import React from 'react';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import Seo from './seo';
import { GatsbyImage } from 'gatsby-plugin-image';

const Novel = ({ title, heroImage, author, publishedDate, shortBio, pdf }) => (
    <article className="container-white container">
        <Seo
            title={title}
            description={shortBio.shortBio}
            keywords={[ 'الصحه العامه', 'الصحه النفسيه', 'شغبطه بقلم د.هبه اللكاوي', 'shaghbatta', 'قصص قصيره' ]}
        />
        <div className="row align-items-center">
            <div className="col-md-5 my-3">
                <div className="image  text-center">
                    <GatsbyImage image={heroImage.gatsbyImageData} className="rounded" alt={title} />
                </div>
            </div>
            <div className="col-md-7 my-3">
                <div className="info p-0">
                    <div className="text-center">
                        <h3>{title}</h3>

                        <p className="mb-3 date">
                            <span>by {author.name}</span> / <span>{publishedDate}</span>
                        </p>
                        <p className="text-center">{shortBio.shortBio}</p>
                        <a className="link" href={pdf.file.url} download>
                            <FaCloudDownloadAlt /> PDF
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </article>
);
export default Novel;
