import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import get from 'lodash/get';
import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Novel from '../components/novel';

class NovelsBook extends React.Component {
    render() {
        const inShop = get(this, 'props.data.inShop.edges');
        const electronic = get(this, 'props.data.electronic.edges');

        return (
            <Layout location={this.props.location}>
                <div className="container my-5">
                    <h2 className="latest-heading py-5 m-0 text-center">
                        <span>روايات حاليه ف الاسواق</span>
                    </h2>
                    <div className="row justify-content-around">
                        {inShop.map(({ node }) => (
                            <div
                                key={node.title}
                                className="col-md-12 my-3"
                                data-sal="fade"
                                data-sal-delay="150"
                                data-sal-easing="ease">
                                <Seo
                                    title={node.title}
                                    keywords={[
                                        'الصحه العامه',
                                        'الصحه النفسيه',
                                        'شغبطه بقلم د.هبه اللكاوي',
                                        'shaghbatta',
                                        'قصص قصيره',
                                        node.title,
                                    ]}
                                />
                                <article className="container container-white">
                                    <div className="row align-items-center">
                                        <div
                                            className="col-md-3 my-2"
                                            data-sal="slide-right"
                                            data-sal-delay="150"
                                            data-sal-easing="ease-in">
                                            <div className="image text-center pt-2">
                                                <GatsbyImage
                                                    image={node.heroImage.gatsbyImageData}
                                                    className="rounded"
                                                    alt={node.title}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-9"
                                            data-sal="slide-left"
                                            data-sal-delay="150"
                                            data-sal-easing="ease-in">
                                            <div className="pt-4 pb-2 px-4">
                                                <div className="text-center">
                                                    <h3 style={{ color: '#2f0233' }}>{node.title}</h3>

                                                    <p className="mb-3 date">
                                                        <span>by {node.author.name}</span> /{' '}
                                                        <span>{node.publishedDate}</span>
                                                    </p>
                                                    <div className="content mt-3 mb-0">
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: node.shortBio.childMarkdownRemark.html,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        ))}
                    </div>
                    <h2 className="latest-heading pt-5 mb-4 text-center">
                        <span>روايات الكترونيه</span>
                    </h2>
                    <p className="text-dark text-center mb-5">لقراءة الرواية قم بتحميل ملف ال PDF</p>
                    <div className="row justify-content-around">
                        {electronic.map(({ node }) => (
                            <div
                                className="col-md-6"
                                key={node.title}
                                data-sal="zoom-in"
                                data-sal-delay="150"
                                data-sal-easing="ease-in">
                                <Novel {...node} />;
                            </div>
                        ))}
                    </div>
                </div>
                <Seo
                    title={`الروايات والكتب`}
                    keywords={[
                        'الصحه العامه',
                        'الصحه النفسيه',
                        'شغبطه بقلم د.هبه اللكاوي',
                        'shaghbatta',
                        'قصص قصيره',
                        'الروايات والكتب',
                    ]}
                />
            </Layout>
        );
    }
}

export default NovelsBook;

export const pageQuery = graphql`
    query NovelsBookQuery {
        inShop: allContentfulNovel(sort: { fields: [publishedDate], order: DESC }, filter: { inShop: { eq: true } }) {
            edges {
                node {
                    title
                    publishedDate(formatString: "MMMM Do, YYYY")
                    author {
                        name
                    }
                    shortBio {
                        childMarkdownRemark {
                            html
                        }
                    }
                    pdf {
                        file {
                            url
                            fileName
                        }
                    }
                    inShop
                    heroImage {
                        gatsbyImageData(layout: FIXED, width: 195)
                    }
                }
            }
        }
        electronic: allContentfulNovel(
            sort: { fields: [publishedDate], order: DESC }
            filter: { inShop: { eq: false } }
        ) {
            edges {
                node {
                    title
                    publishedDate(formatString: "MMMM Do, YYYY")
                    author {
                        name
                    }
                    shortBio {
                        shortBio
                    }
                    pdf {
                        file {
                            url
                            fileName
                        }
                    }
                    inShop
                    heroImage {
                        gatsbyImageData(layout: FIXED, width: 195)
                    }
                }
            }
        }
    }
`;
